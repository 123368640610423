
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

.footer-email{
    color: #fff;
  }
  
  .footer-email:hover{
    color: var(--bg);
  }

footer p {
    opacity: 0.8;
}

#footer {
    background: #000;
    padding: 0 0 30px 0;
    color: #eee;
    font-size: 14px;
  }
  
  #footer img{
    width: 130px;
    margin-left: 4px;
  }
  
  #footer .footer-top {
    background: #111;
    padding: 60px 0 30px 0;
  }
  
  #footer .footer-top .footer-info {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-info h3 {
    font-size: 34px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 10px;
    line-height: 1;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 3px;
    border-left: 4px solid var(--bg);
  }
  
  #footer .footer-top .footer-info p {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 0;
    color: #eee;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: #eee;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: var(--bg);
    color: #fff;
  }
  
  #footer .footer-top h4 {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top h4::before,
  #footer .footer-top h4::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
  }
  
  #footer .footer-top h4::before {
    right: 0;
    background: #555;
  }
  
  #footer .footer-top h4::after {
    background: var(--bg);
    width: 60px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 8px;
    color: #ddd;
  }
  
  #footer .footer-top .footer-links ul li {
    border-bottom: 1px solid #333;
    padding: 10px 0;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #eee;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    color: var(--bg);
  }
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact p {
    line-height: 26px;
  }
  
  #footer .footer-top .footer-newsletter {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-newsletter input[type="email"] {
    border: 0;
    padding: 6px 8px;
    width: 65%;
  }
  
  #footer .footer-top .footer-newsletter input[type="submit"] {
    background: var(--bg);
    border: 0;
    width: 35%;
    padding: 6px 0;
    text-align: center;
    color: #fff;
    transition: 0.3s;
    cursor: pointer;
  }
  
  #footer .footer-top .footer-newsletter input[type="submit"]:hover {
    background: #13a456;
  }
  
  #footer .copyright {
    text-align: center;
    padding-top: 30px;
  }
  
  #footer .credits {
    text-align: center;
    font-size: 13px;
    color: #ddd;
  }